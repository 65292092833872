import React, { Suspense, useContext, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Toaster } from 'react-hot-toast';
import { ErrorBoundary } from 'react-error-boundary';

import { ErrorCodes } from 'src/types/API';

import useGetCurrentUser from './hooks/useGetCurrentUser';

import AuthenticationContext from 'src/contexts/AuthenticationContext';
import { AuthContext } from './external/oauth2/AuthContext';
import ThemeProvider from 'src/contexts/ThemeProvider';

import { DEFAULT_THEME } from 'src/styles/themes';

import Error from './views/error';
import LoadingPage from './views/LoadingPage';

import Gleap from 'gleap';
import 'react-datepicker/dist/react-datepicker.css';
import './scss/style.scss';

if (!Gleap.getInstance().initialized) {
  Gleap.initialize(process.env.REACT_APP_GLEAP_API_KEY || '');
  Gleap.setEnvironment((process.env.REACT_APP_GLEAP_ENV as 'prod' | 'staging') || 'dev');
}

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
      retryDelay: 1_000,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

// Containers
const DefaultLayout = React.lazy(async () => import('./layout/DefaultLayout'));

// Pages
const Login = React.lazy(async () => import('./views/pages/login/Login'));

const Authenticated = () => (
  <Routes>
    <Route path="*" element={<DefaultLayout />} />
  </Routes>
);

const Unauthenticated = () => (
  <Routes>
    <Route path="/login" element={<Login />} />
    <Route path="*" element={<Navigate to="/login" />} />
  </Routes>
);

const RoutesWithAuth = () => {
  const { loginInProgress } = useContext(AuthContext);
  const { data: user, isLoading, refetch } = useGetCurrentUser();
  const [hasFetched, setHasFetched] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!loginInProgress && !hasFetched) {
      refetch();
      setHasFetched(true);
    }
  }, [loginInProgress, hasFetched, refetch]);

  useEffect(() => {
    if (user && !isLoading && location.pathname === '/login') {
      navigate('/users');
    }
  }, [user, isLoading, location, navigate]);

  if (loginInProgress || isLoading) return <LoadingPage />;

  return user ? <Authenticated /> : <Unauthenticated />;
};

const App = () => {
  const fallbackComponent = (err) => <Error {...err} />;
  const handleReset = (status) => {
    window.location.pathname = status === ErrorCodes.Unauthorized ? '/login' : '/';
  };

  return (
    <BrowserRouter>
      <ThemeProvider theme={DEFAULT_THEME}>
        <Suspense fallback={loading}>
          <ErrorBoundary FallbackComponent={fallbackComponent} onReset={handleReset}>
            <QueryClientProvider client={queryClient}>
              <AuthenticationContext>
                <RoutesWithAuth />
              </AuthenticationContext>
            </QueryClientProvider>
          </ErrorBoundary>
          <Toaster position="top-center" />
        </Suspense>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
