import { FC, ReactNode, useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useErrorHandler } from 'react-error-boundary';
import { useLocation } from 'react-router';

import { addAuthToken } from 'src/lib/axios';
import { ErrorCodes } from 'src/types/API';

import { axiosInstances } from '../services/constants';
import { AuthContext } from 'src/external/oauth2/AuthContext';

const ApiProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { idToken } = useContext(AuthContext);

  const queryClient = useQueryClient();
  const location = useLocation();
  const [interceptor, setInterceptor] = useState(false);
  const onError = useErrorHandler();
  const { logOut } = useContext(AuthContext);

  useEffect(() => {
    localStorage.setItem('accessToken', idToken || '');
  }, [idToken]);

  useEffect(() => {
    if (!interceptor && idToken) {
      axiosInstances.forEach((axiosInstance) => {
        axiosInstance.interceptors.request.use(addAuthToken);
        axiosInstance.interceptors.response.use(
          (response) => response,
          (error) => {
            const status = error?.response?.status;
            if (status !== ErrorCodes.Unauthorized) {
              return Promise.reject(error.response);
            }
            if (status === ErrorCodes.Unauthorized && !['/login', '/'].includes(location.pathname)) {
              localStorage.clear();
              queryClient.invalidateQueries();
              logOut();
              onError(error);
            }
          },
        );
      });
      setInterceptor(true);
    }
  }, [idToken, interceptor, location.pathname, onError, queryClient, logOut]);

  return <>{children}</>;
};

export default ApiProvider;
