import { AxiosRequestConfig } from 'axios';

export const addAuthToken = (config: AxiosRequestConfig) => {
  const token = localStorage.getItem('accessToken');
  if (config) {
    if (token && config.headers) {
      config.headers['Authorization'] = `Bearer ${token}`;
    } else if (config.headers) {
      delete config?.headers['Authorization'];
    }
  }
  return config;
};
