import { ErrorCodes } from 'src/types/API';

export const ERRORS = {
  [ErrorCodes.NotFound]: {
    title: '404',
    icon: 'warning',
    subtitle: 'Page not found',
    description: 'Oops! Looks like you got lost.',
  },
  [ErrorCodes.UnderConstruction]: {
    title: '503',
    icon: 'construction',
    subtitle: 'Under construction',
    description: 'Sorry, this page will be available soon',
  },
  [ErrorCodes.Unauthorized]: {
    title: '401',
    icon: 'security',
    subtitle: 'Not authorized',
    description: 'Sorry, you are not authorized to access this page',
  },
};

export const DEFAULT_ERROR = {
  title: 'Issue',
  icon: 'warning',
  subtitle: 'Unable to visit the page',
  description: 'Sorry, something went wrong. Try again',
};
