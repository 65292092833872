import { createStore, Store } from 'redux';

import { Action, State } from './types/Commons';

const initialState: State = {
  sidebarShow: true,
  sidebarUnfoldable: false,
};

const changeState = (state = initialState, action) => {
  switch (action.type) {
    case 'setSidebarUnfoldable':
      return { ...state, sidebarUnfoldable: action.sidebarUnfoldable };
    default:
      return state;
  }
};

const store: Store<State, Action> = createStore(changeState);

export default store;
