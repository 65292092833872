import BasicButton from 'src/components/Button';
import styled from 'styled-components';

export const Button = styled(BasicButton)`
  align-items: center;
  display: flex;
`;

export const Container = styled.div`
  background: #fff;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 100vw;
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.spacing(1)}px;
`;

export const Title = styled.h1`
  ${({ theme }) => theme.fonts.xl};
  font-weight: 600;
`;

export const Subtitle = styled.h2`
  ${({ theme }) => theme.fonts.l};
  font-weight: 600;
  margin-bottom: ${(props) => props.theme.spacing(2)}px;
`;

export const Description = styled.p`
  ${({ theme }) => theme.fonts.md};
  margin-bottom: ${(props) => props.theme.spacing(4)}px;
`;

export const Row = styled.div`
  display: flex;
`;
