import { ThemeProvider as StyledThemeProvider, ThemeProviderProps, DefaultTheme } from 'styled-components';
import { mergeDeepRight } from 'ramda';

import { DEFAULT_THEME } from 'src/styles/themes';

function ThemeProvider({ theme, children }: ThemeProviderProps<DefaultTheme>) {
  return <StyledThemeProvider theme={mergeDeepRight(theme, DEFAULT_THEME)}>{children}</StyledThemeProvider>;
}

export default ThemeProvider;
