import { createContext, FC, ReactNode, useMemo } from 'react';

import { AuthProvider } from 'src/external/oauth2/AuthContext';
import { TLoginContext } from 'src/types/Authentication';

import useLocalStorage from 'src/hooks/useLocalStorage';

import ApiProvider from './ApiProvider';

export const LoginContext = createContext({} as TLoginContext);
LoginContext.displayName = 'LoginContext';

const AuthenticationContext: FC<{ children: ReactNode }> = ({ children }) => {
  const [provider, setProvider] = useLocalStorage('clientId', '');

  const providerValue = useMemo(() => ({ provider, setProvider }), [provider, setProvider]);

  const authConfig = {
    clientId: provider,
    authorizationEndpoint: `${process.env.REACT_APP_OAUTH_DOMAIN}/oauth2/authorize`,
    tokenEndpoint: `${process.env.REACT_APP_OAUTH_DOMAIN}/oauth2/token`,
    redirectUri: `${window.location.origin}/users`,
    logoutRedirect: `${window.location.origin}/users`,
    logoutEndpoint: `${process.env.REACT_APP_OAUTH_DOMAIN}/logout`,
    scope: 'openid',
    autoLogin: false,
    onSessionExpired: () => {
      setProvider('');
      window.location.href = `${window.location.origin}/login`;
    },
    onLoginError: () => {
      window.location.href = `${window.location.origin}/login`;
    },
  };

  return (
    <LoginContext.Provider value={providerValue}>
      <AuthProvider authConfig={authConfig}>
        <ApiProvider>{children}</ApiProvider>
      </AuthProvider>
    </LoginContext.Provider>
  );
};

export default AuthenticationContext;
