import { isLastIndex } from 'src/utils/arrayUtils';

export const entryToString = ([key, value]: [string, string[] | string]) =>
  Array.isArray(value)
    ? value.reduce(
        (total, str, index) => (isLastIndex(value, index) ? `${total}${key}=${str}` : `${total}${key}=${str}&`),
        '',
      )
    : `${key}=${value}`;

export const stringify = (params: { [key: string]: string | Array<string> }) =>
  Object.entries(params).reduce((total, entry, index, arr) => {
    return `${total}${entryToString(entry)}${isLastIndex(arr, index) ? '' : '&'}`;
  }, '');

export const capitalize = (str: string) => {
  return str?.toString().charAt(0).toUpperCase() + str?.slice(1);
};

export const getFirstLetters = (str: string) => {
  const firstLetters = str
    .split(' ')
    .map((word) => word[0])
    .join('');

  return firstLetters;
};

export const sanitize = (str: string) => {
  // Replace: spaces, ampersand, accented letters and [] {} ()
  return str
    .toString()
    .toLowerCase()
    .replaceAll(/(\s|&)/gi, '-')
    .normalize('NFD')
    .replace(/[\u0300-\u036f]|[{()}]/g, '');
};
