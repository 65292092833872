export const FONTS = {
  '2XS': 'font-size: 10px; line-height: 12px;',
  XS: 'font-size: 12px; line-height: 14px;',
  S: 'font-size: 14px; line-height: 16px;',
  MD: 'font-size: 16px; line-height: 19px;',
  L: 'font-size: 20px; line-height: 23px;',
  XL: 'font-size: 25px; line-height: 29px;',
  '2XL': 'font-size: 31px; line-height: 36px;',
  '3XL': 'font-size: 40px; line-height: 47px;',
  '4XL': 'font-size: 49px; line-height: 57px;',
};
