import { ReactNode, DetailedHTMLProps, ButtonHTMLAttributes, forwardRef, Ref, useMemo } from 'react';

import { PrimaryButton, SecondaryButton, DangerButton } from '../../styles/base/button';

interface IProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  children: ReactNode;
  main?: boolean;
  secondary?: boolean;
  danger?: boolean;
}

function Button({ children, main, secondary, danger, ...buttonProps }: IProps, parentRef: Ref<HTMLButtonElement>) {
  let ButtonComponent;

  if (main) {
    ButtonComponent = PrimaryButton;
  } else if (secondary) {
    ButtonComponent = SecondaryButton;
  } else if (danger) {
    ButtonComponent = DangerButton;
  } else {
    ButtonComponent = PrimaryButton;
  }

  const SelectedButton = useMemo(() => ButtonComponent, [ButtonComponent]);

  return (
    <SelectedButton {...buttonProps} ref={parentRef}>
      {children}
    </SelectedButton>
  );
}

export default forwardRef(Button);
