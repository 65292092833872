import { clone } from 'ramda';

import { NavigationTypeItems } from './../types/Commons';

export const isLastIndex = <T>(arr: T[], index: number) => arr.length - 1 === index;

export const isNotEmpty = <T>(arr: T[]) => !!arr?.length;

export const onlyUnique = <T>(value: T, index: number, self: T[]) => self.indexOf(value) === index;

export const uniq = <T>(array: T[]): T[] => array.filter(onlyUnique);

export const cloneElementByIndex = <T>(items: T[], index: number, partial: Partial<T> = {}) => [
  ...items,
  { ...clone(items[index]), ...partial },
];

export const sumArrayBetween = (array: number[], i1: number, i2: number) => {
  if (i1 > i2) return 0;
  let result = 0;
  array.slice(i1, i2).forEach((element) => {
    result += element;
  });
  return result;
};

export const waterExpertNavFilter = (items: NavigationTypeItems[], role: string) => {
  return items.filter((item) => !item.roles || item.roles.includes(role));
};

export const convertModulesNamesToArray = (obj: { [key: string]: any }): { label: string; value: any }[] => {
  return Object.keys(obj).map((key) => ({ label: obj[key], value: key }));
};
