import { api } from 'src/lib/Api';
import { UserProps } from 'src/types/User';

export const getCurrentUser = () => api.get('/v3/users/current?companyId=');

export const getUser = (userId: string) => api.get<UserProps>(`/v1/backoffice/users/${userId}`);

export const getUsers = (userFilter?: string) =>
  api.get<UserProps[]>('/v1/backoffice/users', {
    params: {
      ...(userFilter ? { userFilter: encodeURIComponent(userFilter) } : {}),
    },
  });

export const getUsersCompany = (companyId: string) => api.get<UserProps[]>(`/v1/backoffice/users/${companyId}/company`);

export const createUser = (user: UserProps) =>
  api.post('/v1/backoffice/users/new', { user: { userId: user.user_id, ...user } });

export const updateUser = (user: UserProps) =>
  api.put(`/v1/backoffice/users/${user.user_id}`, { user: { userId: user.user_id, ...user } });

export const triggerUserInvitation = (userId: string) =>
  api.post(`/v1/backoffice/users/trigger-onboarding?userId=${encodeURIComponent(userId)}`);

export const bulkUpdateUsers = ({ users, limit }: { users: Partial<UserProps>[]; limit: number }) =>
  api.put('/v1/backoffice/users/bulk-update', { users, limit });

export const downloadBulkUpdateUsersTemplate = () => api.get('/v1/backoffice/users/bulk-update/template');
