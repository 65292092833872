export enum Colors {
  cyan = '#00DDFF',
  robinsEggBlue = '#02D7D3',
  firefly = '#0F2D38',
  iceberg = '#D9ECF2',
  white = '#FFFFFF',
  black = '#000000',
  orange = '#FFA500',
  coralRed = '#FF414D',
  grassGreenAlpha = '#009A1777',
}

export enum GraphColors {
  red = 'rgb(244, 67, 54)',
  green = 'rgb(76, 175, 80)',
  blue = 'rgb(33, 150, 243)',
  indigo = 'rgb(63, 81, 181)',
  yellow = 'rgb(255, 235, 59)',
  orange = 'rgb(255, 152, 0)',
  pink = 'rgb(236, 64, 122)',
  magenta = 'rgb(200, 45, 200)',
  purple = 'rgb(156, 39, 176)',
  cyan = 'rgb(0, 188, 212)',
  black = 'rgb(31, 31, 31)',
}

export const MetricsColorMapping = {
  discharges: {
    name: 'Discharges',
    color: GraphColors.orange,
  },
  consumption: {
    name: 'Consumption',
    color: GraphColors.red,
  },
  recycled: {
    name: 'Recycled',
    color: GraphColors.green,
  },
  withdrawals: {
    name: 'Total Withdrawals',
    color: GraphColors.indigo,
  },
  withdrawalsground: {
    name: 'Ground Withdrawals',
    color: GraphColors.cyan,
  },
  withdrawalssurface: {
    name: 'Surface Withdrawals',
    color: GraphColors.blue,
  },
  authorizedwithdrawalsground: {
    name: 'Ground Authorized Withdrawals',
    color: GraphColors.pink,
  },
  authorizedwithdrawalssurface: {
    name: 'Surface Authorized Withdrawals',
    color: GraphColors.purple,
  },
  totalauthorizedwithdrawals: {
    name: 'Total Authorized Withdrawals',
    color: GraphColors.magenta,
  },
};

const PRIMARY = {
  FIREFLY: {
    50: '#EBF6FA',
    100: '#D6ECF4',
    200: '#AED9E9',
    300: '#86C6DE',
    400: '#5EB4D3',
    500: '#35A1C9',
    600: '#2B81A0',
    700: '#206078',
    800: '#154050',
    900: '#0F2D38',
  },
  TURQUOISE: {
    50: '#E8FAFD',
    100: '#D0F4FA',
    200: '#A1EAF6',
    300: '#72E0F2',
    400: '#43D6EE',
    500: '#15CCE9',
    600: '#10A3BB',
    700: '#0C7A8C',
    800: '#08515D',
    900: '#04282E',
  },
  JUNGLE: {
    50: '#DCF7F0',
    100: '#B9EFE2',
    200: '#96E8D4',
    300: '#73E0C6',
    400: '#50D9B8',
    500: '#2DD1A9',
    600: '#25AE8D',
    700: '#1E8B71',
    800: '#166854',
    900: '#07221C',
  },
  CARNATION: {
    50: '#FEE6E6',
    100: '#FDCDCD',
    200: '#FC9B9C',
    300: '#FB5E60',
    400: '#FA373A',
    500: '#F80609',
    600: '#C70407',
    700: '#950305',
    800: '#630203',
    900: '#310101',
  },
};

const SECONDARY = {
  CORNFLOWER: {
    50: '#EBE6FE',
    100: '#D7CDFD',
    200: '#AF9BFC',
    300: '#8162FB',
    400: '#5F38F9',
    500: '#3706F8',
    600: '#2C05C6',
    700: '#210395',
    800: '#160263',
    900: '#0B0131',
  },
  SAFFRON: {
    50: '#FFF9DE',
    100: '#FCF4CE',
    200: '#FAEA9D',
    300: '#F7E06D',
    400: '#F5D42F',
    500: '#F3CC0B',
    600: '#C2A309',
    700: '#917A07',
    800: '#615104',
    900: '#302802',
  },
  CARROT: {
    50: '#FFF0E5',
    100: '#FEE1CC',
    200: '#FFC499',
    300: '#FFA766',
    400: '#FF9342',
    500: '#FF6D00',
    600: '#CC5700',
    700: '#994100',
    800: '#662B00',
    900: '#331500',
  },
  SALMON: {
    50: '#FFE5EA',
    100: '#FECCD4',
    200: '#FF99AA',
    300: '#FF8398',
    400: '#FF3355',
    500: '#FF002B',
    600: '#CC0022',
    700: '#990019',
    800: '#660011',
    900: '#330008',
  },
};

const NEUTRAL = {
  GRAY: {
    50: '#F9F9F9',
    100: '#E8E8E8',
    200: '#AFAFAF',
    300: '#616161',
    400: '#4D4D4D',
    500: '#404040',
    600: '#363636',
    700: '#2E2E2E',
    800: '#262626',
    900: '#1A1A1A',
  },
};

const INTERFACE = {
  WARNING: {
    50: '#FAEA9D',
    200: '#FA9D9D',
  },
  ERROR: {
    50: '#FEE7E6',
    100: '#FCCFCE',
    200: '#F9A09E',
    300: '#F6706E',
    400: '#F3413E',
    500: '#F0120E',
    600: '#C00E0B',
    700: '#900A08',
    800: '#600705',
    900: '#300302',
  },
  SUCCESS: {
    50: '#E1F2E3',
    100: '#C3E5C8',
    200: '#9CD4A4',
    300: '#73C480',
    400: '#53B766',
    500: '#30AB4B',
    600: '#279C42',
    700: '#1B8A37',
    800: '#0E792C',
    900: '#005A19',
  },
  INFO: {
    50: '#E5F3FF',
    100: '#CCE6FE',
    200: '#99CDFF',
    300: '#66B5FF',
    400: '#339CFF',
    500: '#1890FF',
    600: '#0069CC',
    700: '#004F99',
    800: '#003466',
    900: '#001A33',
  },
};

const OTHER = {
  CORNFLOWER: {
    600: '#2C05C6',
  },
  EASTERN: {
    700: '#167582',
  },
  SAFFRON: {
    700: '#917A07',
  },
};

export const COLORS = {
  PRIMARY,
  SECONDARY,
  NEUTRAL,
  INTERFACE,
  OTHER,
};

export const ChartColors = [Colors.cyan, Colors.robinsEggBlue, Colors.firefly, Colors.iceberg, Colors.black];
