import { CButton } from '@coreui/react';
import { FallbackProps } from 'react-error-boundary';

import { Container, Header, Title, Subtitle, Description, Row } from './styles';

import { ErrorCodes } from 'src/types/API';
import { IError } from 'src/types/Commons';

import { ERRORS, DEFAULT_ERROR } from 'src/constants/error';

export default function ErrorContainer({ error, resetErrorBoundary }: IError) {
  const status = error?.response?.status;
  const { description, subtitle, title } = status ? ERRORS[status] : DEFAULT_ERROR;

  return (
    <Container>
      <Header>
        <Title>{title}</Title>
      </Header>
      <Subtitle>{subtitle}</Subtitle>
      <Description>{description}</Description>
      <Row>
        <CButton color="secondary" onClick={() => resetErrorBoundary(status)}>
          {status === ErrorCodes.Unauthorized ? 'Go to Login' : 'Go to Dashboard'}
        </CButton>
      </Row>
    </Container>
  );
}

export const DefaultProps: FallbackProps = {
  error: Error(),
  resetErrorBoundary: () => {},
};

export const NotAuthorized = () => <ErrorContainer {...{ status: 401, ...DefaultProps }} />;
