import { useQuery } from 'react-query';

import { QUERIES_KEYS } from 'src/types/API';
import { getCurrentUser } from 'src/services/UsersService';

function useGetCurrentUser() {
  return useQuery(QUERIES_KEYS.CURRENT_USER, () => getCurrentUser().then((res) => res?.data));
}

export default useGetCurrentUser;
