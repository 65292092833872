import { DefaultTheme } from 'styled-components';

import { BREAKPOINTS } from './variables/breakpoints';
import { COLORS } from './variables/colors';
import { FONTS } from './variables/fontSizes';
import { DEFAULT_BORDER_COEFFICIENT, DEFAULT_BORDER_BASE } from './variables/borderRadius';

export const DEFAULT_THEME: DefaultTheme = {
  breakpoints: {
    values: {
      sm: BREAKPOINTS.SM,
      md: BREAKPOINTS.MD,
      lg: BREAKPOINTS.LG,
      xl: BREAKPOINTS.XL,
    },
  },
  spacing: (value: number) => value * 8,
  radius: (coefficient = DEFAULT_BORDER_COEFFICIENT) => `${coefficient * DEFAULT_BORDER_BASE}px`,
  components: {
    statusCard: {
      background: COLORS.NEUTRAL.GRAY[50],
    },
    sideNavLink: {
      background: COLORS.PRIMARY.TURQUOISE[100],
    },
  },
  colors: COLORS,
  fonts: {
    '2xs': FONTS['2XS'],
    xs: FONTS.XS,
    s: FONTS.S,
    md: FONTS.MD,
    l: FONTS.L,
    xl: FONTS.XL,
    '2xl': FONTS['2XL'],
    '3xl': FONTS['3XL'],
    '4xl': FONTS['4XL'],
  },
  utils: {
    noselect: `
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */F
    `,
  },
};
