import styled, { keyframes } from 'styled-components';

const breathAnimation = keyframes`
  0% { background-color: var(--dot-color); }
  50%, 100% { background-color: #ffffffaa; }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(to right, #232526, #414345);
  display: flex;
`;

export const Image = styled.img`
  height: 40;
  margin: 15;
`;

export const DotsFlashing = styled.div`
  --dot-color: #fff1;
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 1px;
  background-color: var(--dot-color);
  color: var(--dot-color);
  animation: ${breathAnimation} 1s infinite linear alternate;
  animation-delay: 0.5s;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    left: -13px;
    width: 8px;
    height: 8px;
    border-radius: 1px;
    background-color: var(--dot-color);
    color: var(--dot-color);
    animation: ${breathAnimation} 1s infinite alternate;
    animation-delay: 0s;
  }

  &::after {
    left: 13px;
    width: 8px;
    height: 8px;
    border-radius: 1px;
    background-color: var(--dot-color);
    color: var(--dot-color);
    animation: ${breathAnimation} 1s infinite alternate;
    animation-delay: 1s;
  }
`;
