import ReactDOM from 'react-dom/client';
import App from './App';
import 'react-app-polyfill/stable';
import 'core-js';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';

const rootElement = document.getElementById('root') as HTMLElement;

const root = ReactDOM.createRoot(rootElement);

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

reportWebVitals();
