import styled, { css } from 'styled-components';

export const BaseButton = css`
  ${({ theme }) => theme.fonts.xs}
  align-items: center;
  background-color: var(--background-color);
  border-radius: ${({ theme }) => theme.radius()};
  color: var(--color);
  display: flex;
  font-weight: 450;
  gap: 8px;
  height: 40px;
  min-width: 40px;
  justify-content: center;
  line-height: 1;
  padding: 8px 16px;
  cursor: pointer;

  & svg {
    flex-shrink: 0;
  }

  &:disabled {
    --color: ${({ theme }) => theme.colors.NEUTRAL.GRAY[200]};
    --background-color: ${({ theme }) => theme.colors.NEUTRAL.GRAY[100]};
    cursor: default;
  }

  & path {
    fill: var(--color);
  }
`;

export const PrimaryButton = styled.button`
  ${BaseButton}
  --color: white;
  --background-color: var(--primary-color);
`;

export const SecondaryButton = styled.button`
  ${BaseButton}
  --color: var(--primary-color);
  --background-color: white;
  border: 1px solid var(--color);
`;

export const DangerButton = styled.button`
  ${BaseButton}
  --color: white;
  --background-color: ${({ theme }) => theme.colors.PRIMARY.CARNATION[600]};
`;
