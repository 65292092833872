import axios from 'axios';
import { stringify } from 'src/utils/queriesUtils';
import { addAuthToken } from './axios';

const DEFAULT_API_URL = 'https://api-stage.waterplan.com';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || DEFAULT_API_URL,
  timeout: 15000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  paramsSerializer: stringify,
});

api.interceptors.request.use(addAuthToken);
