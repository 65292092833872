import WaterPlanLogo from 'src/assets/logos/logowp.png';

import { Container, DotsFlashing, Image } from './styles';

const LoadingPage = () => {
  return (
    <Container>
      <Image src={WaterPlanLogo} alt="Waterplan" />
      <DotsFlashing />
    </Container>
  );
};

export default LoadingPage;
